<template>
    <section>
      <div class="footer-container bg-green-10 w-full flex items-center px-10 mobileL:pt-32 pt-24 mobileL:pb-40 pb-4 text-white-1 text-center font-century font-normal relative">
        <img class="absolute bottom-0 left-0 w-4/12 z-0" src="../../assets/images/home-page/lbplus-footer-element.png" alt="Element footer">
        <img class="absolute bottom-0 right-0 w-4/12 transform -scale-x-100 z-0" src="../../assets/images/home-page/lbplus-footer-element.png" alt="Element footer">
          <div class="h-4/6 w-full">
              <div class="mobileL:grid grid-cols-5 hidden w-10/12 mx-auto mb-6">
                  <router-link class="font-extrabold text-2xl xl:text-3xl 2xl:text-4xl" to="/laybareplus">Home</router-link>
                  <router-link class="font-extrabold text-2xl xl:text-3xl 2xl:text-4xl" to="/laybareplus/branches">Location</router-link>
                  <router-link class="font-extrabold text-2xl xl:text-3xl 2xl:text-4xl" to="/laybareplus/services">Services</router-link>
                  <router-link class="font-extrabold text-2xl xl:text-3xl 2xl:text-4xl" to="/laybareplus/products">Products</router-link>
                  <router-link class="font-extrabold text-2xl xl:text-3xl 2xl:text-4xl" to="/app">App</router-link>
              </div>
  
              <div class="mobileL:grid grid-cols-5 w-10/12 mx-auto mb-8 hidden">
                  <ul>
                      <li class="mb-6 text-base xl:text-xl 2xl:text-2xl">
                          <router-link class="hover:text-violet-1" to="/laybareplus">Introduction</router-link>
                      </li>
                      <li class="mb-6 text-base xl:text-xl 2xl:text-2xl">
                          <router-link class="hover:text-violet-1" to="/laybareplus#why-laybare-plus" @click.native="scrollToId('why-laybare-plus')">Why LayBare+</router-link>
                      </li>
                      <li class="mb-6 text-base xl:text-xl 2xl:text-2xl">
                          <router-link class="hover:text-violet-1" to="/laybareplus#testimonials" @click.native="scrollToId('testimonials')">What our clients say about us</router-link>
                      </li>
                  </ul>
                  <ul>
                      <li class="mb-6 text-base xl:text-xl 2xl:text-2xl">
                          <router-link class="hover:text-violet-1" to="/laybareplus/branches">Search a map</router-link>
                      </li>
                      <li class="mb-6 text-base xl:text-xl 2xl:text-2xl">
                          <router-link class="hover:text-violet-1" to="/laybareplus/branches#map" @click.native="scrollToId('map')">Location map</router-link>
                      </li>
                  </ul>
                  <ul>
                      <li class="mb-6 text-base xl:text-xl 2xl:text-2xl">
                          <router-link class="hover:text-violet-1" to="/laybareplus/services">Our Promos</router-link>
                      </li>
                      <li class="mb-6 text-base xl:text-xl 2xl:text-2xl">
                          <router-link class="hover:text-violet-1" to="/laybareplus/services#service-we-offer" @click.native="scrollToId('service-we-offer')">Service We Offer</router-link>
                      </li>
                      <li class="mb-6 text-base xl:text-xl 2xl:text-2xl">
                          <router-link class="hover:text-violet-1" to="/laybareplus/services#our-packages" @click.native="scrollToId('our-packages')">Our Packages</router-link>
                      </li>
                      <li class="mb-6 text-base xl:text-xl 2xl:text-2xl">
                          <router-link class="hover:text-violet-1" to="/laybareplus/services#know-before-you-go" @click.native="scrollToId('know-before-you-go')">Know Before you go</router-link>
                      </li>
                  </ul>
                  <ul>
                      <li class="mb-6 text-base xl:text-xl 2xl:text-2xl">
                          <router-link class="hover:text-violet-1" to="/laybareplus/products">Our Promos</router-link>
                      </li>
                      <li class="mb-6 text-base xl:text-xl 2xl:text-2xl">
                          <router-link class="hover:text-violet-1" to="/laybareplus/products">Customer Feedbacks</router-link>
                      </li>
                      <li class="mb-6 text-base xl:text-xl 2xl:text-2xl">
                          <router-link class="hover:text-violet-1" to="/laybareplus/products#our-products" @click.native="scrollToId('our-product')">Our Products</router-link>
                      </li>
                  </ul>
                  <ul>
                      <li class="mb-6 text-base xl:text-xl 2xl:text-2xl">
                          <router-link class="hover:text-violet-1" to="/download">Laybare is in your device</router-link>
                      </li>
                      <li class="mb-6 text-base xl:text-xl 2xl:text-2xl">
                          <router-link class="hover:text-violet-1" to="/download">User Feedback</router-link>
                      </li>
                      <li class="mb-6 text-base xl:text-xl 2xl:text-2xl">
                          <router-link class="hover:text-violet-1" to="/download">App Benefits</router-link>
                      </li>
                  </ul>
              </div>
  
              <div class="grid mobileL:grid-cols-9 gap-4 mobileL:gap-0 -mt-20 mobileL:mt-0 mobileL:mb-12 mb-6 w-11/12 mx-auto">
                <router-link class="font-extrabold text-base xl:text-xl 2xl:text-2xl" to="/laybareplus/plc-perks">PLC Perks</router-link>
                <router-link class="font-extrabold text-base xl:text-xl 2xl:text-2xl" to="/laybareplus/promos">Promos</router-link>
                <router-link class="font-extrabold text-base xl:text-xl 2xl:text-2xl" to="/laybareplus/blogs">Blogs</router-link>
                <router-link class="font-extrabold text-base xl:text-xl 2xl:text-2xl" to="/laybareplus/about-us">About Us</router-link>
                <router-link class="font-extrabold text-base xl:text-xl 2xl:text-2xl" to="/laybareplus/careers">Careers</router-link>
                <router-link class="font-extrabold text-base xl:text-xl 2xl:text-2xl" to="/laybareplus/faqs">FAQs</router-link>
                <router-link class="font-extrabold text-base xl:text-xl 2xl:text-2xl" to="/laybareplus/legal-terms">Legal Terms</router-link>
                <router-link class="font-extrabold text-base xl:text-xl 2xl:text-2xl" to="/laybareplus/contact-us">Contact Us</router-link>
                <router-link class="font-extrabold text-base xl:text-xl 2xl:text-2xl" to="/laybareplus/safety-standards">Safety Standards</router-link>
              </div>
  
              <div class="mobileL:grid mobileL:grid-cols-3 w-full items-center">
                  <div class="bg-white-1 w-full hidden mobileL:block" style="height: 2px;"></div>
                  <div class="grid grid-cols-4 mobileL:w-full w-3/4 m-auto">
                      <a href="https://www.facebook.com/laybareplus" class="w-10 h-10 md:w-12 md:h-12 xl:w-14 2xl:w-16 xl:h-14 2xl:h-16 rounded-full border-2 flex items-center justify-center mx-auto" target="_blank">
                            <i class="fab fa-facebook-f fa-lg"></i>
                      </a>
                      <a href="https://www.instagram.com/laybareplus/" class="w-10 h-10 md:w-12 md:h-12 xl:w-14 2xl:w-16 xl:h-14 2xl:h-16 rounded-full border-2 flex items-center justify-center mx-auto" target="_blank">
                            <i class="fab fa-instagram fa-lg"></i>
                      </a>
                      <a href="https://twitter.com/laybareph" class="w-10 h-10 md:w-12 md:h-12 xl:w-14 2xl:w-16 xl:h-14 2xl:h-16 rounded-full border-2 flex items-center justify-center mx-auto" target="_blank">
                            <i class="fab fa-twitter fa-lg"></i>
                      </a>
                      <a href="https://www.tiktok.com/@officiallaybare" class="w-10 h-10 md:w-12 md:h-12 xl:w-14 2xl:w-16 xl:h-14 2xl:h-16 rounded-full border-2 flex items-center justify-center mx-auto">
                            <i class="fab fa-tiktok fa-lg"></i>
                      </a>
                  </div>
                  <div class="bg-white-1 w-full hidden mobileL:block" style="height: 2px;"></div>
              </div>
  
              <div class="relative footer-brand-logo mt-4 mobileL:mt-6 z-20">
                  <img class="w-32 xl:w-40 2xl:w-48 mx-auto" src="../../assets/images/home-page/lb-plus-logo-2.png" alt="Lay Bare Plus">
                  <div class="text-base xl:text-lg 2x:text-xl z-20">
                      <p>Copyright &copy; {{new Date().getFullYear()}} Lay Bare Waxing Philippines Inc.</p>
                  </div>
              </div>
          </div>
      </div>
    </section>
  </template>

<script>
export default {
    methods: {
        scrollToId(id) {
            document.getElementById(id).scrollIntoView();
        }
    }
}
</script>