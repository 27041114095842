<template>
    <div class="relative">
        <div class="w-full absolute block z-20">
            <Header/>
        </div>
        <div class="space-top lbplus-contact-banner-container w-full z-25">
            <div class="relative h-full w-full flex justify-center items-end flex-col">
                <img class="absolute top-0 left-0 h-full object-cover z-5 ml-3 md:ml-0 lg:ml-24 2xl:ml-32 z-15" src="../../../assets/images/contact-us/lbplus-contactus-banner.png" alt="Contact Us Banner">
                <div class="flex flex-col item z-10 max-w-md lg:max-w-md 2xl:max-w-xl p-0 mobileL:p-4 text-left mobileL:mt-8 
                    ml-32 md:-mr-12 lg:mr-24 2xl:mr-52 md:gap-8 2xl:gap-12 leading-snug">
                    <img class="w-6 hidden mobileL:block mobileL:w-16 xl:w-20 2xl:w-24" src="../../../assets/images/home-page/flower.png" alt="Flower">
                    <h1 class="lbplus-contact-banner-title flex justify-start mobileS:text-2xl xs:text-3xl md:text-4xl lg:text-6xl 2xl:text-8xl text-white-1">
                        Contact Us
                    </h1>
                    <p class="font-montserrat text-sm sm:text-base md:text-lg lg:text-2xl 2xl:text-3xl pr-6 mobileL:pr-32 lg:pr-24 2xl:pr-36 text-white-1">
                        Have questions or comments? We will 
                        get back to you as soon as we can!
                    </p>
                </div>
            </div>
        </div>
        <div class="relative py-6 mobileL:py-16 text-green-12">
            <img class="absolute inset-0 w-full h-full object-cover" src="../../../assets/images/contact-us/lbplus-body-bg.png" alt="Background Image">
            <div class="relative flex flex-col items-center justify-center gap-3 mobileL:gap-10">
                <h2 class="text-3xl md:text-5xl lg:text-7xl 2xl:text-9xl font-mermaid">We are always ready to help!</h2>
                <p class=" font-montserrat w-2/3 mobileL:w-3/5 md:text-lg lg:text-3xl 2xl:text-4xl text-center leading-tight">
                    You may email us at <a class="bg-pink-11 pr-2 text-white-1" href="mailto:customercare@lay-bare.com">
                    customercare@lay-bare.com</a>
                    and we’ll get in touch with you from Mondays to Fridays, 9AM-6PM.
                </p>
                <p class="font-montserrat font-semibold md:text-lg lg:text-3xl 2xl:text-4xl text-center px-10 mobileL:px-0">Keep in touch in our social media platforms!</p>
                <div class="flex">
                    <ol class="flex gap-6 mobileL:gap-8 2xl:gap-10">
                        <li><a href="https://www.facebook.com/laybareplus">
                            <img class="w-12 lg:w-16 2xl:w-auto" src="../../../assets/images/contact-us/lbplus-fb.png" alt="">
                        </a></li>
                        <li><a href="https://www.instagram.com/laybareplus/">
                            <img class="w-12 lg:w-16 2xl:w-auto" src="../../../assets/images/contact-us/lbplus-ig.png" alt="">
                        </a></li>
                        <li><a href="https://twitter.com/laybareph">
                            <img class="w-12 lg:w-16 2xl:w-auto" src="../../../assets/images/contact-us/lbplus-twt.png" alt="">
                        </a></li>
                        <li><a href="https://www.tiktok.com/@officiallaybare">
                            <img class="w-12 lg:w-16 2xl:w-auto" src="../../../assets/images/contact-us/lbplus-tiktok.png" alt="">
                        </a></li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="w-full absolute block z-20">
            <Footer/>
        </div>
  </div>
</template>

<script>
import Header from '../../../components/navigation/LaybareHeader.vue'
import Footer from '../../../components/footer/LaybarePlusFooter.vue'

export default {
    components: {
        Header,
        Footer,
    },
    mounted() {
        this.$gtag.pageview('/laybareplus/contact-us')
    },
}
</script>

<style>
.lbplus-contact-banner-container {
    height: 700px;
    background-size: cover;
    background: linear-gradient(180deg, #f38b98 0%, #f0858d 100%);
}

@media screen and (min-width: 640px) and (max-width: 1023px) {
    .lbplus-contact-banner-container {
        height: 45dvh;
    }
}

.space-top {
  margin-top: 90px;
}

@media (min-width: 1280px) {
  .space-top {
    margin-top: 100px;
    }
}

.lbplus-contact-banner-title {
    color: #FFF;
    text-align: center;
    font-family: Mermaid;
    font-style: normal;
    font-weight: 600;
}

@media (min-width: 1536px) {
    .lbplus-contact-banner-container {
      height: 110vh;
    }
    .space-top {
      margin-top: 128px;
    }
}

@media (max-width:480px) {
    .lbplus-contact-banner-container{
    height:255px;
    background: linear-gradient(180deg, #f6c5c9 0%, #fcbfc6 100%);
    }
    .space-top {
    margin-top: 60px;
    }
}

</style>